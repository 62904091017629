


/**
 * Web Animations `@angular/platform-browser/animations`
 * Only required if AnimationBuilder is used within the application and using IE/Edge or Safari.
 * Standard animation support in Angular DOES NOT require any polyfills (as of Angular 6.0).
 **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 */

 // (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 // (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 // (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames

 /*
 * in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 * with the following flag, it will bypass `zone.js` patch for IE/Edge
 */
// (window as any).__Zone_enable_cross_context_check = true;

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */
import 'zone.js/dist/zone';  // Included with Angular CLI.



/***************************************************************************************************
 * APPLICATION IMPORTS
 */

(window as any).global = window;

/**
 * This is for monaco editor errors
 * @see https://github.com/Microsoft/monaco-editor/issues/790#issuecomment-378452532
 */
Promise.all = function (values: any): Promise<any> {
    let resolve: (v: any) => void;
    let reject: (v: any) => void;
    const promise = new this((res, rej) => {
      resolve = res;
      reject = rej;
    });
    let count = 0;
    let index = 0;
    const resolvedValues: any[] = [];
    for (let value of values) {
      if (!(value && value.then)) {
        value = this.resolve(value);
      }
      value.then(( (i) => (val: any) => {
            resolvedValues[i] = val;
            count--;
            if (!count) {
                resolve(resolvedValues);
            }
          })(index),
          reject);
      count++;
      index++;
    }
    if (!count) { resolve(resolvedValues); }
    return promise;
};
